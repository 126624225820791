import { Component, computed, inject, input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';
import { CommunityRoutes } from '../../community/community.routes';
import { RootResource } from '../../community/domain/resource';
import { CommunityProfile } from '../../community/domain/community-profile';

@Component({
  selector: 'app-community-card',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './community-card.component.html',
  styleUrl: './community-card.component.scss'
})
export class CommunityCardComponent {
  private readonly router = inject(Router);

  content = input.required<RootResource<CommunityProfile>>();

  logoPath = computed<string | null>(() => this.content().data.logoLink);
  title = computed<string>(() => this.content().data.title);
  description = computed<string>(() => {
    let text = this.content().data.shortDescription + '\n' + this.content().data.content.elements[0]?.content; // todo don: get all text elements not only first and make sure elements are text, not other media
    return text.replace('\n\n', '\n');
  });

  onItemClick() {
    void this.router.navigate([CommunityRoutes.community(this.content().creationTimestamp, this.content().uuid)]);
  }
}
