<div class="item-container" (click)="onItemClick()">
  <div class="logo-section">
    <img src="{{logoPath() || 'assets/images/logo-placeholder.svg'}}" alt="{{title()}}" class="logo"
         width="82"
         height="36">
  </div>
  <div class="text-section px-3 pt-3 px-sm-5 pt-sm-5">
    <h4 class="title">{{ title() }}</h4>
    <p class="description">{{ description() }}</p>
  </div>
</div>
