import { Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ResourceCardComponent } from '../resource-card/resource-card.component';
import { UserContentApiService } from '../../api/user/user-content-api.service';
import { Observable } from 'rxjs';
import { RootResource } from '../../community/domain/resource';
import { CommunityProfile } from '../../community/domain/community-profile';
import { CommunityCardComponent } from '../community-card/community-card.component';

@Component({
  selector: 'app-my-communities',
  standalone: true,
  imports: [
    AsyncPipe,
    ResourceCardComponent,
    CommunityCardComponent
  ],
  templateUrl: './my-communities.component.html',
  styleUrl: './my-communities.component.scss'
})
export class MyCommunitiesComponent {
  private readonly userContentApiService = inject(UserContentApiService);

  communities: Observable<RootResource<CommunityProfile>[]> = this.userContentApiService.getCommunitiesWithCreatorRights();
}
