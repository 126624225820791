import {Routes} from "@angular/router";
import { MyInterestsComponent } from './my-interests/my-interests.component';
import { MyContentComponent } from './my-content/my-content.component';
import { MyCommunitiesComponent } from './my-communities/my-communities.component';

export enum MyContentRoutes {
  latestPosts = 'lastest-posts',
  myInterests = 'my-interests',
  myPassion = 'my-passion',
  myCommunities = 'my-communities'
}

export const routes: Routes = [{
  path: '',
  component: MyContentComponent,
  children: [
    // {path: 'profile', component: CustomerStateComponent},
    {path: MyContentRoutes.myInterests, component: MyInterestsComponent},
    {path: MyContentRoutes.myCommunities, component: MyCommunitiesComponent},
    {path: '', redirectTo: MyContentRoutes.myInterests, pathMatch: 'full'},
  ]
}];
