<div class="container p-0 p-sm-2 pt-lg-5">
  @if (contentItems | async; as items) {
    @if (!items.length) {
      <div class="no-data">
        You have no Feeds or Collections to show yet. <br>
        Just go explore content communities have created on the Discover page. <br>
        We'll add Feeds and Collections you where interested in automatically to this page.
      </div>
    }

    <div class="items-container">
      @for (contentItem of items; track contentItem.uuid) {
        <app-resource-card [content]="contentItem"></app-resource-card>
      }
    </div>
  }
</div>
