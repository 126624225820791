<div class="item-container" (click)="onItemClick()">
  @if (firstMediaElement()) {
    <div class="media-section">
      <app-generic-element [data]="firstMediaElement()!"></app-generic-element>
    </div>
  }
  <div class="text-section px-3 py-3 px-md-5" [ngClass]="firstMediaElement() ? 'py-md-4' : 'py-md-5'">
    <h4 class="title">{{ content()?.data?.title }}</h4>
    @if (firstTextElement()) {
      <div class="description-preview" [class.long]="!firstMediaElement()">
        <app-generic-element [data]="firstTextElement()!"></app-generic-element>
      </div>
    }
  </div>
  <div class="ident-section">
    <div class="type">{{ contentType() }}</div>
    <div class="community">
      <img src="{{content()?.community?.data?.logoLink}}" alt="{{ content()?.community?.data?.title}}" class="logo" width="82"
           height="36">
    </div>
  </div>
</div>
