import { Component, computed, effect, inject, input } from '@angular/core';
import {ContentType} from '../../api/user/domain/content-type';
import {ContentItem} from '../../api/user/domain/content-item';
import { NgClass, NgIf, NgOptimizedImage } from '@angular/common';
import {Router} from '@angular/router';
import {AppRoutes} from '../../app.routes';
import {CommunityRoutes} from '../../community/community.routes';
import {ContentItemWithCommunity} from '../domain/content-item-with-community';
import { GenericElementComponent } from '../../generic-element/generic-element.component';
import { ElementData } from '../../generic-element/elementData';
import { MarkdownTag } from '../../community/post/editor/markdown-tag';

@Component({
  selector: 'app-resource-card',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgIf,
    GenericElementComponent,
    NgClass
  ],
  templateUrl: './resource-card.component.html',
  styleUrl: './resource-card.component.scss'
})
export class ResourceCardComponent {
  private readonly router = inject(Router);
  private readonly mediaTags: MarkdownTag[] = [
    MarkdownTag.image,
    MarkdownTag.video,
    MarkdownTag.youTubeVideo
  ];
  private readonly textTags: MarkdownTag[] = [
    MarkdownTag.text,
    MarkdownTag.markdown
  ];

  content = input.required<ContentItemWithCommunity>();

  firstMediaElement = computed<ElementData | undefined>(() => {
    return this.content()?.data.content?.elements?.find(element => this.mediaTags.some(tag => element.kind === tag));
  });

  firstTextElement = computed<ElementData | undefined>(() => {
    return this.content()?.data.content?.elements?.find(element => this.textTags.some(tag => element.kind === tag));
    // return this.content()?.data.content?.elements[0];
  });

  contentType = computed<string>(() => {
    const type: ContentType = this.content()?.type!;

    if (type === ContentType.feed)
      return 'Feed';
    if (type === ContentType.collection)
      return 'Collection';

    return 'Other';
  })

  onItemClick() {
    if (this.content()?.type === ContentType.feed) {
      void this.router.navigate([CommunityRoutes.feed(
        this.content()?.community.creationTimestamp!,
        this.content()?.community.uuid!,
        this.content()?.creationTimestamp!,
        this.content()?.uuid!
      )]);
    } else if (this.content()?.type === ContentType.collection) {
      void this.router.navigate([CommunityRoutes.collection(
        this.content()?.community.creationTimestamp!,
        this.content()?.community.uuid!,
        this.content()?.creationTimestamp!,
        this.content()?.uuid!
      )]);
    }
  }
}
