import {Component, HostBinding} from '@angular/core';
import {AsyncPipe, NgIf, NgOptimizedImage} from '@angular/common';
import {MyContentRoutes} from '../my-content-routes';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {UserContentApiService} from '../../api/user/user-content-api.service';
import {CommunityRoutes} from '../../community/community.routes';
import {Observable} from 'rxjs';
import {RootResource} from '../../community/domain/resource';
import {CommunityProfile} from '../../community/domain/community-profile';
import {
  ShowScrollBarOnScrollDirective
} from '../../elements/show-scroll-bar-on-scroll/show-scroll-bar-on-scroll.directive';
import { ScrollToTopButtonComponent } from '../../elements/scroll-to-top-button/scroll-to-top-button.component';

@Component({
  selector: 'app-my-content',
  standalone: true,
  imports: [
    NgIf,
    NgOptimizedImage,
    RouterLink,
    RouterOutlet,
    AsyncPipe,
    RouterLinkActive,
    ShowScrollBarOnScrollDirective,
    ScrollToTopButtonComponent
  ],
  templateUrl: './my-content.component.html',
  styleUrl: './my-content.component.scss'
})
export class MyContentComponent {
  SecureRoutes = MyContentRoutes;
  // followingCommunities: Observable<RootResource<CommunityProfile>[]> = this.communitiesApiService.getFollowedCommunities();

  @HostBinding('class') class = 'd-flex w-100';

  constructor(
    private readonly communitiesApiService: UserContentApiService
  ) {
  }

  removeFocus(event: MouseEvent): void {
    (event.target as HTMLElement).blur();  // Remove focus from the clicked element
  }

  protected readonly CommunityRoutes = CommunityRoutes;
}
