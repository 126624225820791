<div class="content d-flex w-100">
  <!-- Sidenav -->
  <nav class="sidenav d-flex flex-column p-md-3 p-2 navbar-dark col-2" style="background-color: var(--bs-primary); max-width: 14rem">
    <div class="flex-shrink-0 pt-3">
      <ul class="nav navbar-nav nav-pills p-lg-3 text-center">
        <li class="nav-item">
          <a [routerLink]="SecureRoutes.myCommunities" class="nav-link" routerLinkActive="nav-link-active" aria-current="page" (click)="removeFocus($event)">My Communities</a>

          <a [routerLink]="SecureRoutes.myInterests" class="nav-link" routerLinkActive="nav-link-active" aria-current="page" (click)="removeFocus($event)">My Interests</a>
        </li>
      </ul>
      <hr style="color: var(--hover-color)">
    </div>
    <div class="flex-grow-1 overflow-y-auto">
<!--      todo: shall com back in altered form, to my interests however-->
<!--      <div class="followed-communities">Communities I follow:</div>-->
<!--      <ul class="nav navbar-nav nav-pills p-lg-3">-->
<!--        @for (community of (followingCommunities | async); track followingCommunities) {-->
<!--          <a [routerLink]="CommunityRoutes.community(community.creationTimestamp, community.uuid)" class="navbar-brand text-center community-logo">-->
<!--            <img ngSrc="{{community.data.logoLink}}" alt="{{ community?.data?.title}}" class="logo" width="192" height="52">-->
<!--          </a>-->
<!--        }-->
<!--        &lt;!&ndash;        <div class="overflow-y-auto" style="max-height: 65vh">&ndash;&gt;-->
<!--&lt;!&ndash;        <ng-content select="[sidenav]"></ng-content>&ndash;&gt;-->
<!--        &lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </ul>-->
    </div>

    <p class="mb-0 mt-2 flex-shrink-0">
      <span style="color: #818181">Powered by </span>
      <a href="https://keldysh.io" target="_blank" style="color: var(--hover-color)">keldysh.io</a>
    </p>
  </nav>

  <!-- Main Content -->
  <div #scrollToTop id="communityMainContent" class="main-content col overflow-y-auto" appShowScrollBarOnScroll>
    <div class="p-0 p-sm-3">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-scroll-to-top-button [scrollContainer]="scrollToTop"></app-scroll-to-top-button>
