import {Component, inject} from '@angular/core';
import {UserContentApiService} from '../../api/user/user-content-api.service';
import {AsyncPipe} from '@angular/common';
import {map, Observable, tap} from 'rxjs';
import {ContentItem} from '../../api/user/domain/content-item';
import {ResourceCardComponent} from '../resource-card/resource-card.component';
import {combineLatest} from 'rxjs';
import {ContentItemWithCommunity} from '../domain/content-item-with-community';
import {RootResource} from '../../community/domain/resource';
import {CommunityProfile} from '../../community/domain/community-profile';

@Component({
  selector: 'app-my-interests',
  standalone: true,
  imports: [
    AsyncPipe,
    ResourceCardComponent
  ],
  templateUrl: './my-interests.component.html',
  styleUrl: './my-interests.component.scss'
})
export class MyInterestsComponent {
  private readonly userContentApiService = inject(UserContentApiService);

  contentItems: Observable<ContentItemWithCommunity[]> =
    // combineLatest([
    this.userContentApiService.getFollowedContent();
  // this.userContentApiService.getFollowedCommunities()
  // ])
  //   .pipe(
  //     map(([followedContent, followedCommunities]) => this.addCommunity(followedContent, followedCommunities))
  //   );

  // private addCommunity(contentItems: ContentItem[], communities: RootResource<CommunityProfile>[]): ContentItemWithCommunity[] {
  //   const newItems: ContentItemWithCommunity[] = [];
  //
  //   contentItems.forEach(item => {
  //     newItems.push({
  //       ...item,
  //       community: communities.find(community => community.uuid === item.assignmentId)!
  //     });
  //   });
  //
  //   return newItems;
  // }

}
